<template>
  <div>
    <c-table
      ref="table"
      :title="`${equipType.name} 목록`"
      :columns="equipColumns"
      :data="equipData"
      :merge="equipMerge"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <!-- <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" @btnClicked="deleteHazardChem" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addHazardChem" />
          <c-btn v-if="editable&&!disabled" label="저장" icon="save" @btnClicked="saveHazardChem" />
        </q-btn-group>
      </template> -->
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'facility-status-table-hazard-chem',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    facilityStatusTable: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      }),
    },
    equipType: {
      type: Object,
      default: () => ({
        code: '',
        name: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [],
        columns: [],
        data: [],
      },
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let _height = this.contentHeight ? this.contentHeight - 120 : 700
      return _height + 'px';
    },
    equipData() {
      return this.equipType.code === 'device' ? this.facilityStatusTable.equipDevices : this.facilityStatusTable.equipPowers;
    },
    equipMerge() {
      let _merge = [];
      if (this.equipType.code === 'device') {
        _merge = [
          { index: 0, colName: 'equipmentNo' },
          { index: 1, colName: 'equipmentNo' },
          { index: 2, colName: 'equipmentNo' },
        ];
      }
      return _merge
    },
    equipColumns() {
      let _cols
      if (this.equipType.code === 'device') {
        _cols = [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '장치번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'deviceName',
            field: 'deviceName',
            label: '장치명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'chems',
            field: 'chems',
            label: '취급물질(상태)',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'devicePartName',
            field: 'devicePartName',
            label: '장치구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'conectorStatus',
            field: 'conectorStatus',
            label: '연결구상태',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'volume',
            field: 'volume',
            label: '용량',
            align: 'center',
            style: 'width:150px',
            type: 'html',
            sortable: true,
          },
          {
            label: '압력(MPa)',
            align: 'center',
            child: [
              {
                name: 'pressOperation',
                field: 'pressOperation',
                label: '운전',
                align: 'center',
                style: 'width:60px',
                sortable: true,
              },
              {
                name: 'pressDesign',
                field: 'pressDesign',
                label: '설계',
                align: 'center',
                style: 'width:60px',
                sortable: true,
              },
            ]
          },
          {
            label: '온도(℃)',
            align: 'center',
            child: [
              {
                name: 'tempOperation',
                field: 'tempOperation',
                label: '운전',
                align: 'center',
                style: 'width:60px',
                sortable: true,
              },
              {
                name: 'tempDesign',
                field: 'tempDesign',
                label: '설계',
                align: 'center',
                style: 'width:60px',
                sortable: true,
              },
            ]
          },
          {
            label: '사용재질',
            align: 'center',
            child: [
              {
                name: 'materialMain',
                field: 'materialMain',
                label: '본체',
                align: 'center',
                style: 'width:120px',
                sortable: true,
              },
              {
                name: 'materialSubPart',
                field: 'materialSubPart',
                label: '부속품',
                align: 'center',
                style: 'width:120px',
                sortable: true,
              },
              {
                name: 'materialGasket',
                field: 'materialGasket',
                label: '개스킷',
                align: 'center',
                style: 'width:120px',
                sortable: true,
              },
            ]
          },
          {
            name: 'designStandards',
            field: 'designStandards',
            label: '설계표준',
            align: 'left',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'afterHeatTreatYn',
            field: 'afterHeatTreatYn',
            label: '후열처리여부',
            align: 'center',
            sortable: true,
          },
          {
            name: 'nondestructPercent',
            field: 'nondestructPercent',
            label: '비파괴검사율(%)',
            align: 'center',
            sortable: true,
          },
        ]
      } else {
        _cols = [
          {
            name: 'psiPowerEquipmentNo',
            field: 'psiPowerEquipmentNo',
            label: '동력기계번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'powerMachineName',
            field: 'powerMachineName',
            label: '동력기계명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'connect',
            field: 'connect',
            label: '연결 설비',
            child: [
              {
                name: 'inFacility',
                field: 'inFacility',
                label: '인입측',
                align: 'left',
                style: 'width:100px',
                sortable: true,
              },
              {
                name: 'outFacility',
                field: 'outFacility',
                label: '토출측',
                align: 'left',
                style: 'width:100px',
                sortable: true,
              },
            ],
          },
          {
            name: 'chems',
            field: 'chems',
            label: '취급물질',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'specInfo',
            field: 'specInfo',
            label: '명세',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'motorVol',
            field: 'motorVol',
            label: '전동기용량(kW)',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'matQuality',
            field: 'matQuality',
            label: '주요재질',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
        ]
      }
      return _cols;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (this.equipType.code === 'device') {
        this.popupOptions.target = () => import(`${"@/pages/psi/pfi/deviceEquipmentDetail.vue"}`);
        this.popupOptions.title = '장치 및 설비 상세';
        this.popupOptions.param = {
          psiDeviceEquipmentId: row ? row.psiDeviceEquipmentId : '',
        };
      } else {
        this.popupOptions.target = () => import(`${"@/pages/psi/pfi/powerMachineDetail.vue"}`);
        this.popupOptions.title = '동력기계 상세';
        this.popupOptions.param = {
          psiPowerEquipmentId: row ? row.psiPowerEquipmentId : '',
        };
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>